<script setup lang="ts">
import type { NuxtError } from 'nuxt/app'
import ThemeToggle from '#components/library/action/ThemeToggle.vue'

definePageMeta({
  middleware: 'auth',
  layout: 'app-public',
  pageTransition: {
    name: 'fade-left',
    mode: 'out-in',
  },
})

const clearError = (error: NuxtError) => {
  error.value = null
}
</script>

<template>
  <section class="center-lg">
    <div class="mb-4 gap-4 flex justify-center items-center">
      <NuxtLink to="/">
        <BaseLogo size="sm" />
      </NuxtLink>

      <ThemeToggle />
    </div>

    <NuxtErrorBoundary>
      <NuxtPage />
      <template #error="{ error }">
        <figure role="alert">
          <Icon
            class="icon"
            name="exclamation-triangle-reg"
            size="large"
          />
          <figcaption class="caption">
            {{ $t("generics.errors.errorOccurred") }}
          </figcaption>
          <p class="error">
            {{ error }}
          </p>
        </figure>
        <BaseButton
          translation-path="clear"
          color="secondary"
          size="sm"
          @click="clearError(error)"
        />
      </template>
    </NuxtErrorBoundary>
  </section>
</template>

<style scoped lang="scss">
[role="alert"] {
  color: var(--color-error-500);
  display: grid;
  grid-template-columns: [error] 8rem [message] 1fr;
  grid-template-rows: 1fr [error] 1fr [message];
  align-items: center;
  margin-bottom: 2rem;

  .icon {
    grid-column: error;
    grid-row: span 2;
  }

  .caption {
    grid-column: message;
  }

  .error {
    grid-column: message;
  }
}
</style>
